// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-interaction-jsx": () => import("./../../../src/pages/interaction.jsx" /* webpackChunkName: "component---src-pages-interaction-jsx" */),
  "component---src-pages-login-jsx": () => import("./../../../src/pages/login.jsx" /* webpackChunkName: "component---src-pages-login-jsx" */),
  "component---src-pages-sculpture-jsx": () => import("./../../../src/pages/sculpture.jsx" /* webpackChunkName: "component---src-pages-sculpture-jsx" */),
  "component---src-pages-strategy-jsx": () => import("./../../../src/pages/strategy.jsx" /* webpackChunkName: "component---src-pages-strategy-jsx" */),
  "component---src-pages-writing-jsx": () => import("./../../../src/pages/writing.jsx" /* webpackChunkName: "component---src-pages-writing-jsx" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/blog-post.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */)
}

